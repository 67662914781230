<template>
  <div class="calculator container mt-3 mb-2">
    <div class="row">
      <div class="col-6">
        <div><b>เป้าหมาย</b></div>
        <div class="calculator-card mt-2 p-2">
          <span v-if="saleType === 'wholesale'"
            >{{ $numberWithCommas(target) }} บาท</span
          >
          <span v-else>-</span>
        </div>
      </div>
      <div class="col-6">
        <div><b>ขาดอีก</b></div>
        <div class="calculator-card mt-2 p-2">
          <span v-if="saleType === 'wholesale'"
            >{{ $numberWithCommas(request) }} บาท</span
          >
          <span v-else>-</span>
        </div>
      </div>
    </div>
    <div class="mt-2"><b>สินค้าแนะนำ</b></div>
    <div class="calculator-card p-3">
      <div v-for="(i, index) in filterRecommendList" :key="index" class="row">
        <div class="col-6">{{ index + 1 }}. {{ i.name }}</div>
        <div class="col-6">
          {{ i.point }} คะแนน
          <span
            v-if="i.active"
            class="d-flex align-items-center float-right blue-text"
            ><img
              class="mr-2"
              src="@/assets/icon/plus-circle-fill.svg"
              height="16px"
              width="16px"
              @click="addRecommendItem(i.id)"
              data-tag="addItem"
            />
            เพิ่ม</span
          >
          <span v-else class="d-flex align-items-center float-right gray-text"
            ><img
              class="mr-2"
              src="@/assets/icon/plus-circle.svg"
              height="16px"
              width="16px"
            />
            เพิ่ม</span
          >
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-6">
        <div><b>รายการสินค้า</b></div>
      </div>
    </div>
    <div v-if="itemList.length > 0">
      <div
        v-for="(i, index) in itemList"
        :key="index"
        class="calculator-card p-3 mx-0 mt-2"
      >
        <div class="row mb-2">
          <div class="col-6">
            <b>{{ index + 1 }}. {{ i.product }} {{ i.size }} {{ i.unit }}</b>
          </div>
          <div class="col-6 d-flex justify-content-end align-items-center">
            <img
              src="@/assets/icon/trash-alt-regular.svg"
              height="16px"
              width="16px"
              @click="removeItem(index)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex align-items-center">จำนวน</div>
          <div class="col-6 d-flex justify-content-end align-items-center">
            <img
              class="mr-2"
              src="@/assets/icon/iconmonstr-minus-circle-thin.svg"
              height="16px"
              width="16px"
              @click="() => i.qty--"
            /><input
              class="small-input-field form-control mr-2"
              v-model="i.qty"
            /><img
              src="@/assets/icon/iconmonstr-plus-circle-thin.svg"
              height="16px"
              width="16px"
              @click="() => i.qty++"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-6">ราคา (บาท)</div>
          <div class="col-6 d-flex justify-content-end align-items-center">
            <span class="price-text font-weight-bold">{{
              $numberWithCommas(i.price * i.qty)
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="itemList.length === 0" class="mt-3">
      <button
        class="btn btn-outline-secondary blue-text w-100 add-button lg-button"
        @click="openModal"
      >
        <img
          class="mr-2"
          src="@/assets/icon/plus-circle-fill.svg"
          height="16px"
          width="16px"
        />
        เพิ่มรายการ
      </button>
    </div>
    <div v-if="itemList.length > 0">
      <div v-if="totalPoint !== 0 && totalPrice !== 0">
        <hr />
        <div class="row">
          <div class="col-6">
            <b>ราคารวม</b>
          </div>
          <div class="col-6 d-flex justify-content-end align-items-center">
            {{ $numberWithCommas(totalPrice) }}<span class="ml-4">บาท</span>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <b>คะแนนที่จะได้รับ</b>
          </div>
          <div class="col-6 d-flex justify-content-end align-items-center">
            {{ $numberWithCommas(totalPoint) }}<span class="ml-2">คะแนน</span>
          </div>
        </div>
        <hr />
      </div>
      <div :class="`row${totalPoint !== 0 && totalPrice !== 0 ? '' : ' mt-3'}`">
        <div class="col-6">
          <button
            class="btn btn-outline-secondary blue-text w-100 add-button lg-button"
            @click="openModal"
          >
            <img
              class="mr-2"
              src="@/assets/icon/plus-circle-fill.svg"
              height="16px"
              width="16px"
            />
            เพิ่มรายการ
          </button>
        </div>
        <div class="col-6">
          <button
            class="btn btn-primary w-100 add-button lg-button"
            @click="requestCalculate"
          >
            คำนวน
          </button>
        </div>
      </div>
      <!-- <button
        class="btn btn-primary mt-2 w-100"
        @click="resetList"
        data-tag="resetList"
      >
        <span>เริ่มต้นใหม่</span>
      </button> -->
    </div>
    <b-modal id="calculator_modal" hide-footer centered>
      <template v-slot:modal-header-close>
        <i
          class="close-modal icon-cancel-circled-outline"
          @click="clearData"
        ></i>
      </template>
      <div class="d-block form-group mt-4">
        <label for="brand"><b>แบรนด์</b></label>
        <select
          class="custom-select"
          name="brand"
          id="brand"
          required
          v-model="item.brand"
        >
          <option disabled selected value>เลือก</option>
          <option
            v-for="(i, index) in filterBrandItems"
            :key="index"
            :value="i"
          >
            {{ i }}
          </option>
        </select>
      </div>
      <div class="d-block form-group mt-3">
        <label for="product"><b>สินค้า</b></label>
        <select
          class="custom-select"
          name="product"
          id="product"
          :disabled="item.brand === ''"
          required
          v-model="item.product"
        >
          <option disabled selected value>เลือก</option>
          <option
            v-for="(i, index) in filterProductItems"
            :key="index"
            :value="i.name"
          >
            {{ i.name }}
          </option>
        </select>
      </div>
      <div class="d-block form-group mt-3">
        <label><b>จำนวน</b></label>
        <input
          class="form-control"
          name="qty"
          id="qty"
          :disabled="item.brand === '' || item.product === ''"
          type="number"
          required
          v-model="item.qty"
        />
      </div>
      <div class="text-center row">
        <div class="col">
          <button
            class="btn btn-primary mt-4 w-100"
            @click="addItem"
            data-tag="addItem"
          >
            <span>เพิ่มรายการ</span>
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  computed: {
    shopCode() {
      const { user_data } = this.$store.state;
      const shopCode = user_data?.shopCode;
      return shopCode;
    },
    saleType() {
      const { user_data } = this.$store.state;
      const customerSaleType = user_data?.customerSaleType;
      return customerSaleType ? customerSaleType.toLowerCase() : "";
    },
    filterBrandItems() {
      const { brandItems, productItems } = this;
      const arrayList =
        brandItems.reduce((o, e) => {
          if (productItems.length > 0) {
            productItems.some((i) => {
              return e === i.brand && o.push(e);
            });
          }
          return o;
        }, []) || [];
      return arrayList;
    },
    filterProductItems() {
      const { productItems, item } = this;
      const arrayList =
        productItems.length > 0
          ? productItems.reduce((o, e) => {
              if (e.brand === item.brand) {
                o.push({ ...e });
              }
              return o;
            }, []) || []
          : [];
      return arrayList;
    },
    filterRecommendList() {
      const { recommendList, itemList } = this;
      const resultList = [];
      recommendList.forEach((i) => {
        resultList.push({
          ...i,
          active: true,
        });
      });
      if (itemList.length > 0) {
        itemList.forEach((i) => {
          resultList.forEach((j, index) => {
            if (i.id === j.id) {
              resultList[index].active = false;
            }
          });
        });
      }
      return resultList;
    },
    totalPrice() {
      const { resultTotalPrice } = this;
      return resultTotalPrice;
    },
    totalPoint() {
      const { resultTotalPoint } = this;
      return resultTotalPoint;
    },
  },
  data: () => ({
    target: 0,
    request: 0,
    recommendList: [],
    brandItems: [],
    productItems: [],
    item: {
      brand: "",
      product: "",
      qty: 1,
    },
    resultTotalPrice: 0,
    resultTotalPoint: 0,
    itemList: [],
  }),
  async mounted() {
    if (this.shopCode) this.getTarget();
    await this.getProducts();
  },
  methods: {
    async getTarget() {
      window.loading(true);
      const date = new Date();
      const year = date.getFullYear();
      const month = date.getMonth();
      const res = await fetch(
        `${process.env.VUE_APP_API_URL}/shop/${
          this.shopCode
        }/year/${year}/month/${month > 11 ? 0 : month + 1}/target`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${this.$JWT}`,
          },
        }
      );
      const { status } = res;
      switch (status) {
        case 200: {
          const { targetReach, currentTarget } = await res.json();
          this.target = targetReach;
          this.request = targetReach - currentTarget;
          window.loading(false);
          break;
        }
        default:
          window.loading(false);
          break;
      }
    },
    async getProducts() {
      window.loading(true);
      const res = await fetch(`${process.env.VUE_APP_API_URL}/products`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.$JWT}`,
        },
      });
      const { status } = res;
      switch (status) {
        case 200: {
          const { brands, result } = await res.json();
          const recommendList = [];
          const dataList = [];
          this.brandItems = brands;
          result.forEach((i) => {
            if (i.isRecommend) {
              recommendList.push({
                ...i,
                brand: i?.brand?.name,
              });
            }
            dataList.push({ ...i, brand: i?.brand?.name });
          });
          this.productItems = dataList;
          this.recommendList = recommendList;
          window.loading(false);
          break;
        }
        default:
          window.loading(false);
          break;
      }
    },
    async requestCalculate() {
      window.loading(true);
      const { itemList } = this;
      const ids = [];
      const quantities = [];
      itemList.forEach((i) => {
        ids.push(i.id);
        quantities.push(parseInt(i.qty));
      });
      const formData = {
        ids,
        quantities,
      };
      const res = await fetch(
        `${process.env.VUE_APP_API_URL}/products/calculate`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.$JWT}`,
          },
          body: JSON.stringify(formData),
        }
      );
      const { status } = res;
      switch (status) {
        case 200: {
          const { totalPrice, totalPoint } = await res.json();
          this.resultTotalPrice = totalPrice;
          this.resultTotalPoint = totalPoint;
          window.loading(false);
          break;
        }
        default:
          window.loading(false);
          break;
      }
    },
    resetList() {
      this.itemList = [];
    },
    clearData() {
      this.item = {
        brand: "",
        product: "",
        qty: 1,
      };
    },
    openModal() {
      this.$bvModal.show("calculator_modal");
    },
    addItem() {
      const { productItems } = this;
      const { brand, product, qty } = this.item;
      const arrayList =
        productItems.reduce((o, e) => {
          if (e.brand === brand) {
            o.push({ ...e });
          }
          return o;
        }, []) || [];
      const index = arrayList.findIndex((i) => i.name === product);
      this.itemList.push({
        brand,
        qty,
        id: arrayList[index].id,
        product: arrayList[index].name,
        price: arrayList[index].price,
        point: arrayList[index].point,
        size: arrayList[index].size,
        unit: arrayList[index].unit,
      });
      const newIndex = productItems.findIndex((i) => i.name === product);
      const newList = this.removeItemOnce(productItems, newIndex);
      this.productItems = newList;
      this.$bvModal.hide("calculator_modal");
      this.clearData();
    },
    addRecommendItem(id) {
      this.$bvModal.show("calculator_modal");
      const { recommendList, productItems } = this;
      recommendList.forEach((i) => {
        if (i.id === id) {
          this.item = {
            ...this.item,
            brand: i.brand,
            product: i.name,
          };
        }
      });
      const index = productItems.findIndex((i) => i.name === this.item.product);
      this.item = {
        ...this.item,
        product: productItems[index].name,
      };
    },
    removeItem(index) {
      const { itemList, productItems } = this;
      const insertList = productItems.length > 0 ? productItems : [];
      insertList.push({
        id: itemList[index].id,
        brand: itemList[index].brand,
        price: itemList[index].price,
        point: itemList[index].point,
        name: itemList[index].product,
        size: itemList[index].size,
        unit: itemList[index].unit,
      });
      const arrayList = this.removeItemOnce(itemList, index);
      this.productItems = insertList;
      this.itemList = arrayList;
    },
    removeItemOnce(arr, index) {
      // const index = arr.indexOf(value);
      if (index > -1) {
        arr.splice(index, 1);
      }
      return arr;
    },
  },
  watch: {
    async shopCode(nextProps, prevProps) {
      if (nextProps !== prevProps) {
        await this.getTarget();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$blue: #189bd6;
$gray: #ced3da;
$light-blue: #f0f6ff;

.calculator {
  font-size: 14px;
  .blue-text {
    color: $blue;
    font-weight: 500;
  }
  .gray-text {
    color: $gray;
    font-weight: 500;
  }
  .calculator-card {
    background: $light-blue;
    border-radius: 10px;
    // line-height: 1.5;
    text-align: center;
    .row {
      text-align: left;
    }
  }
  .add-button {
    line-height: 1.5;
    &.lg-button {
      padding: 1rem;
    }
  }
  .small-input-field {
    border-radius: 4px;
    // height: 24px;
    // width: 60px;
    text-align: center;
  }
  .price-text {
    width: 100%;
    text-align: center;
  }
}
</style>